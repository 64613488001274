export default {
  namespaced: true,
  state: {
    client: [],
  },
  getters: {
    getClientByGroupId: state => id => state.client.filter(v => v.id === id),
    getClientAllList: state => state.client,
  },
  mutations: {
    SET_CLIENT_LIST(state, val) {
      state.client = val
    },
    PUT_CLIENT_LIST(state, val) {
      const indexOf = state.client.findIndex(v => v.id === val.id)
      if (indexOf) {
        state.client.splice(indexOf, 1, val)
      } else {
        state.client.push(val)
      }
    },
    PUSH_CLIENT(state, val) {
      state.client.push(val)
    },
    UPDATE_ELEMENT(state, el) {
      const index = state.client.findIndex(e => e.id === el.id)
      if (index) {
        state.client.splice(index, 1, el)
      }
    },
    DELETE_CLIENT_BY_ID(state, id) {
      const indexOf = state.client.findIndex(client => client.id === id)
      if (indexOf) {
        state.client.splice(indexOf, 1)
      }
    },
    CLEAR_CLIENT_LIST(state) {
      state.client = []
    },
  },
  actions: {},
}
