import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import groupIP from './ipam-group-ip'
import ipamIpV4 from './ipam-ipv4'
import ipamProvider from './ipam-provider'
import ipamServers from './ipam-servers'
import billingClient from './billing-client'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    groupIP,
    ipamIpV4,
    ipamProvider,
    ipamServers,
    billingClient,
  },
  strict: process.env.DEV,
})
