export default [
  {
    path: '/dashboard/ipam/subnet',
    name: 'dashboard-ipam-subnet',
    component: () => import('@/views/ipam/ipv4/DashboardIpamSubnet.vue'),
  },
  {
    path: '/dashboard/ipam/ip-utils',
    name: 'dashboard-ipam-ip-utils',
    component: () => import('@/views/ipam/ip-utils/DashboardIpamUtils.vue'),
  },
  {
    path: '/dashboard/ipam/provider',
    name: 'dashboard-ipam-provider',
    component: () => import('@/views/ipam/provider/DashboardProvider.vue'),
  },
  {
    path: '/dashboard/ipam/server',
    name: 'dashboard-ipam-server',
    component: () => import('@/views/ipam/server/DashboardIpamServer.vue'),
  },
]
