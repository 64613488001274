export default {
  namespaced: true,
  state: {
    servers: [],
  },
  getters: {
    getServerList: state => state.servers,
    getServerByLongMainIP: state => ip => state.filter(g => g.ip === ip),
    getServerByStringMainIP: state => ip => state.filter(g => g.mainIp === ip),
  },
  mutations: {
    SET_SERVER_LIST(state, val) {
      state.servers = val
    },
    PUSH_SERVER(state, val) {
      state.servers.push(val)
    },
    PUT_SERVER_LIST(state, val) {
      const indexOf = state.servers.findIndex(v => v.id === val.id)
      if (indexOf) {
        state.servers.splice(indexOf, 1, val)
      }
    },
    CLEAR_SERVER_LIST(state) {
      state.servers = []
    },
    DELETE_SERVER_BY_IP(state, ip) {
      const indexOf = state.servers.findIndex(g => g.mainIp === ip)
      if (indexOf) {
        state.servers.splice(indexOf, 1)
      }
    },
    DELETE_SERVER_BY_ID(state, serverId) {
      const indexOf = state.servers.findIndex(s => s.id === serverId)
      if (indexOf) {
        state.servers.splice(indexOf, 1)
      }
    },
  },
  actions: {},
}
