export default {
  namespaced: true,
  state: {
    ipV4: [],
  },
  getters: {
    getIpV4ByGroupSlug: state => slug => state.ipV4.filter(ip => ip.groupSlug === slug),
    getIpV4ById: state => id => state.ipV4.filter(ip => ip.id === id),
    getIpV4GroupByGroupSlugForTree: state => {
      const arrayByGroup = []
      state.ipV4.forEach(ip => {
        const el = { id: ip.id, label: `${ip.subnetAddress}/${ip.subnetMask}` }
        if (arrayByGroup[ip.groupSlug] === undefined) {
          arrayByGroup[ip.groupSlug] = [el]
        } else {
          arrayByGroup[ip.groupSlug].push(ip)
        }
      })
      return arrayByGroup
    },
    getIpV4AllList: state => state.ipV4,
  },
  mutations: {
    SET_IPV4_LIST(state, val) {
      state.ipV4 = val
    },
    PUT_IPV4_LIST(state, val) {
      const indexOf = state.ipV4.findIndex(v => v.id === val.id)
      if (indexOf) {
        state.ipV4.splice(indexOf, 1, val)
      }
    },
    PUSH_IPV4_LIST(state, val) {
      state.ipV4.push(val)
    },
    UPDATE_ELEMENT(state, el) {
      const index = state.ipV4.findIndex(i => i.id === el.id)
      if (index) {
        state.ipV4.splice(index, 1, el)
      }
    },
    DELETE_IP_V4_BY_ID(state, id) {
      const indexOf = state.ipV4.findIndex(ip => ip.id === id)
      if (indexOf) {
        state.ipV4.splice(indexOf, 1)
      }
    },
    CLEAR_IPV4_LIST(state) {
      state.ipV4 = []
    },
  },
  actions: {},
}
