export default {
  namespaced: true,
  state: {
    provider: [],
  },
  getters: {
    getProviderByGroupId: state => id => state.provider.filter(v => v.id === id),
    getProviderAllList: state => state.provider,
  },
  mutations: {
    SET_PROVIDER_LIST(state, val) {
      state.provider = val
    },
    PUT_PROVIDER_LIST(state, val) {
      const indexOf = state.provider.findIndex(v => v.id === val.id)
      if (indexOf) {
        state.provider.splice(indexOf, 1, val)
      }
    },
    PUSH_PROVIDER(state, val) {
      state.provider.push(val)
    },
    UPDATE_ELEMENT(state, el) {
      const index = state.provider.findIndex(e => e.id === el.id)
      if (index) {
        state.provider.splice(index, 1, el)
      }
    },
    DELETE_PROVIDER_BY_ID(state, id) {
      const indexOf = state.provider.findIndex(provider => provider.id === id)
      if (indexOf) {
        state.provider.splice(indexOf, 1)
      }
    },
    CLEAR_PROVIDER_LIST(state) {
      state.provider = []
    },
  },
  actions: {},
}
