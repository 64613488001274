export default {
  namespaced: true,
  state: {
    groupIP: [],
  },
  getters: {
    getGroupIPList: state => state.groupIP,
    getGroupIPBySlug: state => slug => state.groupIP.find(el => el.slug === slug),
  },
  mutations: {
    SET_GROUP_IP_LIST(state, val) {
      state.groupIP = val
    },
    PUSH_GROUP_IP(state, val) {
      state.groupIP.push(val)
    },
    CLEAR_GROUP_IP_LIST(state) {
      state.groupIP = []
    },
    DELETE_GROUP_IP_BY_SLUG(state, slug) {
      const index = state.groupIP.findIndex(value => value.slug === slug)
      console.log(slug)
      console.log(index)
      if (index !== -1) {
        state.groupIP.splice(index, 1)
      }
    },
  },
  actions: {},
}
