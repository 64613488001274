import Vue from 'vue'

// axios
import axios from 'axios'

// const baseApiUrl = 'https://ipcp.ipmen.network:8081'
const baseApiUrl = 'https://ipcp.ipmen.network:8090'
// eslint-disable-next-line no-unused-vars
const baseApiUrlIp = 'http://51.89.127.73:8090'
// eslint-disable-next-line no-unused-vars
const bgpApiBaseApiUrlIp = 'https://rest.bgp-api.net/api/v1'
const routinatorApiBaseApiUrl = 'https://routinator.ipmen.network/api/v1'
const whoisIPCpApiBaseApiUrl = 'https://whois.ipmen.network:8092/whoismen/api/v1'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: `${baseApiUrl}/api/v1`,
})

const axiosAdm = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: `${baseApiUrl}/adm/api/v1`,
})

const axiosBgpApi = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: bgpApiBaseApiUrlIp,
})

const axiosRoutinatorApi = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: routinatorApiBaseApiUrl,
})

const axiosWhoisIPCpApi = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: whoisIPCpApiBaseApiUrl,
})

Vue.prototype.$http = axiosIns
Vue.prototype.$httpAdm = axiosAdm
Vue.prototype.$httpBgpApi = axiosBgpApi
Vue.prototype.$httpRoutinatorApi = axiosRoutinatorApi
Vue.prototype.$httpWhoisIPCpApi = axiosWhoisIPCpApi

export default axiosIns
export {
  axiosAdm, baseApiUrl, axiosBgpApi, axiosRoutinatorApi, axiosWhoisIPCpApi,
}
